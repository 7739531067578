import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Home } from "components";
import {
  About,
  Blog,
  Blogs,
  Certificate,
  Contact,
  Hotel,
  NotFound,
  PolicyOne,
  PolicyThree,
  PolicyTwo,
  PQRS,
  Questions,
  Sagrilaft,
  EthicalLine,
  Payments,
} from "page";
import MainLayout from "layout/MainLayout";
import MenuRobot from "common/MenuRobot";
import Index from "page/Admin/Index";

const keyGoogle = process.env.REACT_APP_GOOGLE_ANALYTICS;

const RoutesGAC = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    ReactGA.initialize(keyGoogle);
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
      title: pathname,
    });

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, [pathname]);

    return null;
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <MainLayout>
          <MenuRobot />
          <Routes>
            <Route path="/" errorElement={<h1>Error</h1>} element={<Home />} />
            <Route
              path="/d77cdf4f4ab48f705fbed94d26c7ee03831add4b9cbe2a4c7c578f8cc7361215"
              element={<Index />}
            />
            <Route
              path="/sagrilaft"
              errorElement={<h1>Error</h1>}
              element={<Sagrilaft />}
            />
            <Route
              path="/about"
              errorElement={<h1>Error</h1>}
              element={<About />}
            />
            <Route
              path="/pqrs"
              errorElement={<h1>Error</h1>}
              element={<PQRS />}
            />
            <Route
              path="/document/:url"
              errorElement={<h1>Error</h1>}
              element={<Certificate />}
            />
            <Route
              path="/politica-de-ley-de-retracto"
              errorElement={<h1>Error</h1>}
              element={<PolicyOne />}
            />
            <Route
              path="/politica-de-proteccion-de-menores"
              errorElement={<h1>Error</h1>}
              element={<PolicyTwo />}
            />
            <Route
              path="/bio-seguridad"
              errorElement={<h1>Error</h1>}
              element={<PolicyThree />}
            />
            <Route
              path="/contacto"
              errorElement={<h1>Error</h1>}
              element={<Contact />}
            />
            <Route
              path="/questions"
              errorElement={<h1>Error</h1>}
              element={<Questions />}
            />
            <Route
              path="/blog"
              errorElement={<h1>Error</h1>}
              element={<Blogs />}
            />
            <Route
              path="/blog/:id"
              errorElement={<h1>Error</h1>}
              element={<Blog />}
            />
            <Route
              path="/:destino/:hotel"
              errorElement={<h1>Error</h1>}
              element={<Hotel />}
            />
            <Route
              path="/mercadopago"
              errorElement={<h1>Error</h1>}
              element={<Payments />}
            />
            <Route
              path="/linea-etica"
              errorElement={<h1>Error</h1>}
              element={<EthicalLine />}
            />
            <Route
              path="*"
              errorElement={<h1>Error</h1>}
              element={<NotFound />}
            />
          </Routes>
        </MainLayout>
      </BrowserRouter>
    </>
  );
};

export default RoutesGAC;
