import React from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { uploadFileGac } from "services/FileManager";
import { ReactComponent as IconUploadFile } from "assets/icons/uploadFile.svg";
import { saveBanner } from "services/banners";
import "styles/dashboardAdmin/DashboardBanners.css";

const DashboardBanners = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();

  const prevImageMobile = watch("img_mobile");
  const prevImageTablet = watch("img_tablet");
  const prevImagePC = watch("img_pc");
  const prevImageLarge = watch("img_large");

  const onSubmit = handleSubmit(async (data) => {
    try {
      const urls = {};
      // Subir cada imagen y almacenar la URL devuelta
      for (const key of Object.keys(data)) {
        if (data[key]) {
          const formData = new FormData();
          formData.append("fileToUpload", data[key][0]);
          const response = await uploadFileGac(formData);
          urls[key] = response.data.url;
        }
      }
      urls.link = data.link;
      await saveBanner(urls);
      toast.success("Banner creado correctamente");
    } catch (error) {
      console.error("Error al subir las imágenes:", error);
      toast.error("Error al subir las imágenes:", error);
    } finally {
      reset();
    }
  });

  return (
    <div className="container-upload-banners">
      <h2 className="upload-title">Crear Banner</h2>
      <Toaster />
      <form onSubmit={onSubmit}>
        <section className="upload-grid">
          <div className="upload-container">
            <label className="upload-label">
              <IconUploadFile className="upload-icon" />
              <span className="upload-text">
                Subir imagen para dispositivo mobil
              </span>
              <input
                type="file"
                accept="image/*"
                {...register("img_mobile", {
                  required: "El campo Imagen Mobil es obligatorio",
                })}
                className="hidden-input"
              />
              {errors.img_mobile && (
                <span className="text-danger">{errors.img_mobile.message}</span>
              )}
            </label>
            {prevImageMobile && prevImageMobile.length > 0 && (
              <img
                src={URL.createObjectURL(prevImageMobile[0])}
                alt="imagen mobil banner"
                className="upload-image"
              />
            )}
          </div>
          <div className="upload-container">
            <label className="upload-label">
              <IconUploadFile className="upload-icon" />
              <span className="upload-text">Subir imagen para Tablet</span>
              <input
                type="file"
                accept="image/*"
                {...register("img_tablet", {
                  required: "El campo Imagen Tablet es obligatorio",
                })}
                className="hidden-input"
              />
              {errors.img_tablet && (
                <span className="text-danger">{errors.img_tablet.message}</span>
              )}
            </label>
            {prevImageTablet && prevImageTablet.length > 0 && (
              <img
                src={URL.createObjectURL(prevImageTablet[0])}
                alt="imagen tablet banner"
                className="upload-image"
              />
            )}
          </div>
          <div className="upload-container">
            <label className="upload-label">
              <IconUploadFile className="upload-icon" />
              <span className="upload-text">Subir imagen para PC</span>
              <input
                type="file"
                accept="image/*"
                {...register("img_pc", {
                  required: "El campo Imagen PC es obligatorio",
                })}
                className="hidden-input"
              />
              {errors.img_pc && (
                <span className="text-danger">{errors.img_pc.message}</span>
              )}
            </label>
            {prevImagePC && prevImagePC.length > 0 && (
              <img
                src={URL.createObjectURL(prevImagePC[0])}
                alt="imagen pc banner"
                className="upload-image"
              />
            )}
          </div>
          <div className="upload-container">
            <label className="upload-label">
              <IconUploadFile className="upload-icon" />
              <span className="upload-text">
                Subir imagen para pantallas grandes
              </span>
              <input
                type="file"
                accept="image/*"
                {...register("img_large", {
                  required: "El campo Imagen pantallas grandes es obligatorio",
                })}
                className="hidden-input"
              />
              {errors.img_large && (
                <span className="text-danger">{errors.img_large.message}</span>
              )}
            </label>
            {prevImageLarge && prevImageLarge.length > 0 && (
              <img
                src={URL.createObjectURL(prevImageLarge[0])}
                alt="imagen screen large banner"
                className="upload-image"
              />
            )}
          </div>
        </section>
        <div className="container-link">
          <label htmlFor="">Link banner</label>
          <input className="link-input" {...register("link")} />
        </div>

        <button
          className="btn-login"
          style={{ width: "100%", padding: ".6em" }}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            ></div>
          ) : (
            "Crear Banner"
          )}
        </button>
      </form>
    </div>
  );
};

export default DashboardBanners;
