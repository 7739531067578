import { apiConfig } from "./apiConfig";

const saveBanner = async (payload) => {
  return await apiConfig.post("/banners/create", payload);
};

const getBannerGac = async () => {
  return await apiConfig.get("/banners");
};

export { saveBanner, getBannerGac };
